import React, { useState, useEffect } from 'react';
import { useStateValue, useTokenContext } from '../store';
import { Cog, VolumeMute, VolumeUp, Male, Female, Times } from '../assets/svg/icons';
import Select from '../components/select';

function TtsUI(props) {
    const {
        initialLanguage,
        initialVisible,
        initialActive,
        initialGender,
        initialVoice,
        withUI,
        style,
    } = props;

    const [
        {
            tts: { language, visible, active, gender, voice },
        },
        dispatch,
    ] = useStateValue();
    const { languages } = useTokenContext();
    const [visibleOptions, setVisibleOptions] = useState(false);

    const ttsLanguages = languages.filter(item => item.services.indexOf('tts') > -1);
    const defaultLanguage = ttsLanguages.find(lng => lng.value === initialLanguage);

    // When component is mounted, set default values to context
    useEffect(() => {
        dispatch({
            type: 'TTS_setup',
            value: {
                language: defaultLanguage || null,
                visible: initialVisible,
                active: initialActive,
                gender: initialGender,
                voice: true,
            },
        });
    }, []);

    /**
     * Show/hide bottom options of container
     */
    const toggleOptions = () => {
        setVisibleOptions(!visibleOptions);
    };

    /**
     * Hide container
     */
    const closeComponent = () => {
        dispatch({
            type: 'TTS_changeVisible',
            value: false,
        });
    };

    /**
     * Handle source language
     * @param {String} language en | es | fr
     */
    const handleLanguage = language => {
        dispatch({
            type: 'TTS_changeLanguage',
            value: language,
        });
    };

    /**
     * Handle active TTS
     */
    const handleActive = () => {
        dispatch({
            type: 'TTS_changeActive',
            value: !active,
        });
    };

    /**
     * Handle gender
     */
    const handleGender = value => {
        dispatch({
            type: 'TTS_changeGender',
            value: value,
        });
    };

    if (!withUI) return null;
    return (
        <div className={`wrapper-container ${!visible ? 'container-disabled' : ''}`} style={style}>
            <div id="tts" className="container-options">
                <div className="header-container">
                    <div className="header-title">
                        <span>Translated voice</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={toggleOptions}>
                            <Cog />
                        </button>
                        <button className="a-element" onClick={closeComponent}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="box-tts">
                    <div className={`body-container ${!visibleOptions ? 'options-disabled' : ''}`}>
                        <div className="wrapper-btn-tts">
                            <button
                                className={`btn-circle btn ${active ? 'btn-green' : 'btn-primary'}`}
                                type="button"
                                onClick={handleActive}
                            >
                                {active ? <VolumeMute /> : <VolumeUp />}
                            </button>
                            <label>&nbsp;{active ? 'Mute' : 'Unmute'}</label>
                        </div>
                        <div className="wrapper-btn-genders">
                            <button
                                className={`btn btn-sm btn-default ${
                                    gender === 'male' ? 'active' : ''
                                } `}
                                type="button"
                                onClick={() => handleGender('male')}
                            >
                                <Male />
                                <span>&nbsp;Male</span>
                            </button>
                            <button
                                className={`btn btn-sm btn-default ${
                                    gender === 'female' ? 'active' : ''
                                } `}
                                type="button"
                                onClick={() => handleGender('female')}
                            >
                                <Female />
                                <span>&nbsp;Female</span>
                            </button>
                        </div>
                    </div>
                    <div className={`bottom-box ${!visibleOptions ? 'options-disabled' : ''}`}>
                        <div className="form-inline">
                            <div className="settings-container">
                                <div className="settings-container-wrapper">
                                    <span className="settings-container-label">
                                        Target Language
                                    </span>
                                    <div>
                                        <Select
                                            handleChange={handleLanguage}
                                            data={ttsLanguages}
                                            defaultValue={defaultLanguage || null}
                                            value={language}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TtsUI;
