import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchTextToSpeech } from '../request/texttospeech';
import { useStateValue, useTokenContext } from '../store';
import TtsUI from './component';

let ttsCalls = [];
let itemTtsCall = 0;
let auxSpeaking = false;
let auxActiveSTT = false;

function TextToSpeechWrapper(props) {
    const { audioTTS } = useTokenContext();
    const [
        {
            tts: { subsToTTS, voice },
            stt: { active: activeSTT },
        },
        dispatch,
    ] = useStateValue();

    useEffect(() => {
        playbackOrder();
    }, []);

    useEffect(() => {
        auxActiveSTT = activeSTT;
    }, [activeSTT]);

    useEffect(() => {
        if (subsToTTS) {
            handleTextToSpeech(subsToTTS.text, subsToTTS.language);
        }
    }, [subsToTTS]);

    const handleTextToSpeech = async (text, language) => {
        if (text && text !== '') {
            itemTtsCall++;
            ttsCalls.push({
                item: itemTtsCall,
                downloadUrl: null,
                sent: false,
            });
            const textToFetch = text.replace(/>/g, '');

            let response = null;
            if (props.fetchCustom) {
                response = await props.fetchCustom(
                    props.endpoint,
                    textToFetch,
                    language,
                    voice.value,
                    itemTtsCall,
                );
            } else {
                response = await fetchTextToSpeech(
                    props.endpoint,
                    textToFetch,
                    language,
                    voice.value,
                    itemTtsCall,
                );
            }
            const { downloadUrl, indexCall } = response;
            if (downloadUrl) {
                handleTTSResponse(indexCall, downloadUrl);
            } else {
                handleTTSError(indexCall);
            }
        }
    };

    const handleTTSResponse = (itemTtsCall, downloadUrl) => {
        for (var i = 0; i < ttsCalls.length; i++) {
            if (ttsCalls[i].item == itemTtsCall) {
                ttsCalls[i].downloadUrl = downloadUrl;
                break;
            }
        }
    };

    const handleTTSError = itemTtsCall => {
        for (var i = 0; i < ttsCalls.length; i++) {
            if (ttsCalls[i].item == itemTtsCall) {
                ttsCalls.splice(i, 1);
                break;
            }
        }
    };

    const playbackOrder = () => {
        if (ttsCalls.length > 0 && ttsCalls[0].downloadUrl != undefined && !ttsCalls[0].sent) {
            if (auxActiveSTT) {
                auxSpeaking = true;
                dispatch({
                    type: 'STT_changeActive',
                    value: false,
                });
            }
            ttsCalls[0].sent = true;
            playbackUrl(ttsCalls[0].downloadUrl);
        }
        setTimeout(playbackOrder, 100); // recall
    };

    const playbackUrl = url => {
        audioTTS.src = url;
        audioTTS.onended = function() {
            ttsCalls.splice(0, 1);
            window.webkitURL.revokeObjectURL(url);
            if (auxSpeaking) {
                dispatch({
                    type: 'STT_changeActive',
                    value: true,
                });
                auxSpeaking = false;
            }
        };
        audioTTS.play();
    };
    return <TtsUI {...props} />;
}

TextToSpeechWrapper.defaultProps = {
    initialGender: 'male',
    initialVisible: true,
    initialActive: false,
    withUI: true,
    fetchCustom: null,
};

TextToSpeechWrapper.propTypes = {
    initialVendor: PropTypes.string.isRequired,
    initialLanguage: PropTypes.string.isRequired,
    initialGender: PropTypes.string.isRequired,
    endpoint: PropTypes.object.isRequired,
    fetchCustom: PropTypes.func,
};

const TextToSpeech = {
    TextToSpeechUI: TextToSpeechWrapper,
};

export default TextToSpeech;
