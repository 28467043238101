import React, { useState, useEffect } from 'react';
import { fetchTranslate } from '../request/translate';
import { useStateValue, useTokenContext } from '../store';
import Select from '../components/select';

import { Times, Cog, UserCircle, Microphone, MicrophoneSlash } from '../assets/svg/icons';

function ChatUI(props) {
    const {
        initialSourceLanguage,
        initialTargetLanguage,
        initialVisible,
        messagesList,
        addMessageToChat,
        username,
        messageFromThirdParty,
        endpoint,
        buildMessageChat,
        provider,
        pubnub,
        channelIdPubnub,
        speechRecognition,
        showButtonCloseComponent,
    } = props;

    const [
        {
            chat: { targetLanguage, sourceLanguage, visible, messageFromOtherComponent },
            stt,
        },
        dispatch,
    ] = useStateValue();
    const { token, languages } = useTokenContext();
    const [inputChat, setInputChat] = useState(null);
    const [fontSize, setFontSize] = useState(2);
    const [visibleOptions, setVisibleOptions] = useState(false);

    // When component is mounted, set default values to context
    useEffect(() => {
        const defaultLanguage = languages.find(lng => lng.value === initialSourceLanguage);
        dispatch({
            type: 'CHAT_setup',
            value: {
                sourceLanguage: defaultLanguage,
                targetLanguage: defaultLanguage,
                visible: initialVisible,
            },
        });
    }, []);

    useEffect(() => {
        if (messageFromThirdParty) {
            const { name, speakerLanguage, text } = messageFromThirdParty;
            displayChatMessage(
                speakerLanguage,
                targetLanguage.value,
                targetLanguage.vendor,
                text,
                name,
                'other',
            );
        }
    }, [messageFromThirdParty]);

    useEffect(() => {
        if (messageFromOtherComponent) {
            const { name, speakerLanguage, text } = messageFromOtherComponent;
            displayChatMessage(
                speakerLanguage,
                targetLanguage.value,
                targetLanguage.vendor,
                text,
                name,
                'own',
            );
            const msg = buildMessageChat(name, speakerLanguage, text);

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(msg),
                });
            }
        }
    }, [messageFromOtherComponent]);

    // Update scroll on Chat messages when there's a new text
    useEffect(() => {
        handleScroll();
    }, [messagesList]);

    /**
     * Show the text in the body of component like text list
     * @param {string} sourceLanguage   Source language "en"
     * @param {string} targetLanguage   Target language "es"
     * @param {string} vendor           Vendor "microsoft"
     * @param {string} text             Chat text
     * @param {string} name             User name
     * @param {string} type             Message type: "other" or "own"
     */
    const displayChatMessage = async (sourceLanguage, targetLanguage, vendor, text, name, type) => {
        const msg = buildMessageChat(name, sourceLanguage, text);

        if (text && text.trim() !== '') {
            if (sourceLanguage !== targetLanguage) {
                const auth = token.ttt.find(elem => elem.vendor === vendor);

                const chatTranslated = await fetchTranslate(
                    endpoint,
                    text,
                    sourceLanguage,
                    targetLanguage,
                    vendor,
                    auth,
                );
                if (
                    chatTranslated &&
                    chatTranslated[endpoint.valueResponse] &&
                    chatTranslated[endpoint.valueResponse] !== ''
                ) {
                    const msgTranslated = buildMessageChat(
                        name,
                        targetLanguage,
                        chatTranslated[endpoint.valueResponse],
                        type,
                    );
                    addMessageToChat(msgTranslated, type);
                }
            } else {
                addMessageToChat(msg, type);
            }
        }
    };

    /**
     * Handle input chat to translate/show text
     * @param {Object} e Class Event of component
     * @return {void}
     */
    const handleInputChat = async e => {
        if (e.key === 'Enter' && !e.shiftKey && inputChat && inputChat.trim() !== '') {
            e.preventDefault();
            const msg = buildMessageChat(username, sourceLanguage.value, inputChat);

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(msg),
                });
            }
            displayChatMessage(
                sourceLanguage.value,
                targetLanguage.value,
                targetLanguage.vendor,
                inputChat,
                username,
                'own',
            );
            setInputChat(null);
        }
        if (e.key === 'Enter' && !e.shiftKey && (!inputChat || inputChat.trim() == '')) {
            e.preventDefault();
            return false;
        }
    };

    /**
     * Update class of each <p> element to change font size
     * @param {Integer} size Value to set new font size
     * @return {void}
     */
    const handleFontSize = size => {
        setFontSize(size);
    };

    /**
     * Set scroll to the bottom when new text is rendered
     */
    const handleScroll = () => {
        var container = document.getElementById('list-messages');
        container.scrollTop = container.scrollHeight;
    };

    /**
     * Show/hide bottom options of container
     */
    const toggleOptions = () => {
        setVisibleOptions(!visibleOptions);
    };

    /**
     * Hide container
     */
    const closeComponent = () => {
        dispatch({
            type: 'CHAT_changeVisible',
            value: false,
        });
    };

    /**
     * Handle source language
     * @param {String} language en | es | fr
     */
    const handleSourceLanguage = language => {
        dispatch({
            type: 'CHAT_changeSourceLanguage',
            value: language,
        });
    };

    /**
     * Handle target language
     * @param {String} language en | es | fr
     */
    const handleTargetLanguage = language => {
        dispatch({
            type: 'CHAT_changeTargetLanguage',
            value: language,
        });
    };

    const handleSTT = () => {
        dispatch({
            type: 'STT_changeTarget',
            value: 'chat',
        });
        dispatch({
            type: 'STT_changeActive',
            value: !stt.active,
        });
    };

    const renderMessage = (key, type, name, text, time) => {
        if (type === 'own') {
            return (
                <div key={`chat-${key}`} className="msg-user">
                    <div className="tri-right message-user right-top">
                        <p className={`font-size-${fontSize}`}>{text}</p>
                    </div>
                    <div className="date-chat">{time}</div>
                </div>
            );
        } else {
            return (
                <div key={`chat-${key}`} className="msg-voxeet">
                    <div className="avatar-text">
                        <div className="img-voxeet">
                            <UserCircle />
                            <span>{name}</span>
                        </div>
                        <div className="tri-right message-chat left-top">
                            <p className={`font-size-${fontSize}`}>{text}</p>
                        </div>
                    </div>
                    <div className="date-chat">{time}</div>
                </div>
            );
        }
    };

    return (
        <div
            className={`wrapper-container ${!visible ? 'container-disabled' : ''}`}
            style={props.style}
        >
            <div id="chat" className="container-option">
                <div className="header-container">
                    <div className="header-title">
                        <span>Chat</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={toggleOptions}>
                            <Cog />
                        </button>

                        {showButtonCloseComponent ? (
                            <button className="a-element" onClick={closeComponent}>
                                <Times />
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="box-subtitle">
                    <div
                        id="list-messages"
                        aria-label="container-messages-chat"
                        className={`body-container ${!visibleOptions ? 'options-disabled' : ''}`}
                    >
                        {messagesList.map((msg, index) =>
                            renderMessage(index, msg.type, msg.name, msg.text, msg.time),
                        )}
                    </div>
                    <div
                        className={`wrapper-input-chat ${
                            speechRecognition ? 'wrapper-input-chat-group' : ''
                        }`}
                    >
                        {speechRecognition ? (
                            <div className="wrapper-button-speech-chat">
                                <button
                                    type="button"
                                    disabled={stt.active && stt.target !== 'chat'}
                                    className={`btn ${
                                        stt.active && stt.target === 'chat' ? 'btn-green' : ''
                                    }`}
                                    onClick={handleSTT}
                                >
                                    {stt.active && stt.target ? (
                                        <Microphone />
                                    ) : (
                                        <MicrophoneSlash />
                                    )}
                                </button>
                            </div>
                        ) : null}
                        <div className="wrapper-textarea-chat">
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Type text here"
                                rows="4"
                                value={inputChat || ''}
                                onKeyPress={handleInputChat}
                                onChange={e => setInputChat(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className={`bottom-box ${!visibleOptions ? 'options-disabled' : ''}`}>
                        <div className="form-inline">
                            <div className="settings-container">
                                <div className="settings-container-wrapper ">
                                    <span className="settings-container-label">Chat font size</span>
                                    <div className="size-subtitles">
                                        <label style={{ fontSize: 10 }}>A</label>
                                        <input
                                            type="range"
                                            min="1"
                                            max="5"
                                            step="1"
                                            value={fontSize}
                                            onChange={e => handleFontSize(e.target.value)}
                                        ></input>
                                        <label style={{ fontSize: 14 }}>A</label>
                                    </div>
                                </div>
                            </div>

                            <div className="settings-container">
                                <div className="settings-container-wrapper target-language">
                                    <span className="settings-container-label">
                                        Target Language
                                    </span>
                                    <div>
                                        <Select
                                            handleChange={handleTargetLanguage}
                                            data={languages}
                                            defaultValue={languages.find(
                                                item => item.value === initialTargetLanguage,
                                            )}
                                            value={targetLanguage}
                                        />
                                    </div>
                                </div>
                                <div className="settings-container-wrapper source-language">
                                    <span className="settings-container-label">
                                        Source Language
                                    </span>
                                    <div>
                                        <Select
                                            handleChange={handleSourceLanguage}
                                            data={languages}
                                            defaultValue={languages.find(
                                                item => item.value === initialSourceLanguage,
                                            )}
                                            value={sourceLanguage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatUI;
