import React, { useState, useEffect } from 'react';
import { fetchTranslate } from '../request/translate';
import { useStateValue, useTokenContext } from '../store';
import Select from '../components/select';

let firstPlayAudio = false;
import {
    Cog,
    TrashAlt,
    Times,
    ArrowRight,
    MicrophoneSlash,
    Microphone,
    VolumeMute,
    VolumeDown,
} from '../assets/svg/icons';

function SubtitlesUI(props) {
    const {
        initialSourceLanguage,
        initialTargetLanguage,
        initialVisible,
        subtitles,
        renderSubtitle,
        username,
        style,
        buildMessageSubtitle,
        newSubtitle,
        endpoint,
        showInput,
        showButtonCloseComponent,
        showSourceLanguageSelect,
        showTargetLanguageSelect,
        pubnub,
        provider,
        channelIdPubnub,
        speechRecognition,
        ttsOption,
        preRequestSubtitles,
        postRequestSubtitles,
        render,
    } = props;

    const [
        {
            subtitles: { targetLanguage, sourceLanguage, visible, messageFromOtherComponent },
            tts: { active: activeTTS, gender: genderTTS, voice: voiceTTS },
            stt,
        },
        dispatch,
    ] = useStateValue();
    const { token, audioTTS, languages } = useTokenContext();
    const [inputSubtitles, setInputSubtitles] = useState(null);
    const [fontSize, setFontSize] = useState(2);
    const [bilingual, setBilingual] = useState(false);
    const [visibleOptions, setVisibleOptions] = useState(false);

    // When component is mounted, set default values to context
    useEffect(() => {
        const defaultLanguage = languages.find(lng => lng.value === initialSourceLanguage);
        dispatch({
            type: 'SUBTITLES_setup',
            value: {
                sourceLanguage: defaultLanguage,
                targetLanguage: defaultLanguage,
                visible: initialVisible,
            },
        });
    }, []);

    // incoming message from third party communication dependency
    // e.g. pubnub
    useEffect(() => {
        if (newSubtitle) {
            const { name, speakerLanguage, text } = newSubtitle;
            if (text && text.trim() !== '') {
                handleIncomingMessage(name, speakerLanguage, text);
            }
        }
    }, [newSubtitle]);

    // incoming message from third party Component
    // e.g. STT, Popular phrases, etc.
    useEffect(() => {
        if (messageFromOtherComponent) {
            const { name, speakerLanguage, text } = messageFromOtherComponent;
            displaySubtitle(speakerLanguage, targetLanguage.value, text, name);

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(messageFromOtherComponent),
                });
            }
        }
    }, [messageFromOtherComponent]);

    // Update scroll on Subtitles list when there's a new text
    useEffect(() => {
        handleScroll();
    }, [subtitles]);

    /**
     * Handle incoming message from third party components
     * @param {String} name User name who wrote the message
     * @param {String} incomeLanguage Code language of text
     * @param {String} textIncome Text wrote
     */
    const handleIncomingMessage = async (name, incomingLanguage, textIncome) => {
        let text = textIncome;

        // bilingual validation
        // if incomingLanguage (messageFromOtherComponent) is different of sourceLanguage (Subtitle component)
        // we have to translate that text to render like bilingual text (first line) with sourceLanguage idiom

        // const auth = token.ttt.find(elem => elem.vendor === sourceLanguage.vendor);
        if (bilingual && incomingLanguage !== sourceLanguage.value) {
            const responseRequest = await fetchTranslate(
                endpoint,
                text,
                incomingLanguage,
                sourceLanguage.value,
                undefined,
            );
            text = responseRequest[endpoint.valueResponse];
            displaySubtitle(sourceLanguage.value, targetLanguage.value, text, name);
        } else {
            displaySubtitle(incomingLanguage, targetLanguage.value, text, name);
        }
    };

    /**
     * Show the text in the body of component like text list
     * @param {string} sourceLanguage   Source language "en"
     * @param {string} targetLanguage   Target language "es"
     * @param {string} text             Subtitle text
     * @param {string} name             User name
     */
    const displaySubtitle = async (sourceLanguage, targetLanguage, text, name) => {
        const msg = buildMessageSubtitle(name, sourceLanguage, text);

        if (text && text.trim() !== '') {
            if (sourceLanguage !== targetLanguage) {
                const subtitlesTranslated = await handleRequestSubtitle(
                    text,
                    sourceLanguage,
                    targetLanguage,
                );
                if (subtitlesTranslated) {
                    const msgTranslated = buildMessageSubtitle(
                        name,
                        targetLanguage,
                        subtitlesTranslated,
                    );

                    // bilingual validation
                    // if sourceLanguage is differente of targetLanguage and bilingual is enabled
                    // we have to show two lines per subtitles text
                    // first line: text with sourceLanguage idiom
                    // second line: text with targetLanguage idiom
                    if (bilingual) {
                        msgTranslated.bilingualText = text;
                    }
                    renderSubtitle(msgTranslated);

                    if (activeTTS && voiceTTS) {
                        dispatch({
                            type: 'TTS_setNewTTS',
                            value: {
                                text: subtitlesTranslated,
                                language: targetLanguage,
                            },
                        });
                    }
                }
            } else {
                renderSubtitle(msg);
                if (activeTTS && voiceTTS) {
                    dispatch({
                        type: 'TTS_setNewTTS',
                        value: {
                            text,
                            language: targetLanguage,
                        },
                    });
                }
            }
        }
    };

    // Handle text to make Request to API REST
    // You may handle data before and after make request with custom functions passed by props
    // util to delete/add words, change languages, etc.
    const handleRequestSubtitle = async (text, sourceLanguage, targetLanguage) => {
        let textPre = null;
        let sourceLanguagePre = null;
        let targetLanguagePre = null;

        // handle data before API request
        if (preRequestSubtitles) {
            const responsePreRequest = await preRequestSubtitles(
                text,
                sourceLanguage,
                targetLanguage,
            );
            textPre = responsePreRequest.text;
            sourceLanguagePre = responsePreRequest.sourceLanguage;
            targetLanguagePre = responsePreRequest.targetLanguage;
        }

        const responseRequest = await fetchTranslate(
            endpoint,
            textPre || text,
            sourceLanguagePre || sourceLanguage,
            targetLanguagePre || targetLanguage,
            undefined,
        );

        // handle data after API response
        if (postRequestSubtitles) {
            const responsePostRequest = await postRequestSubtitles(
                text,
                sourceLanguage,
                targetLanguage,
            );
            return responsePostRequest.text;
        }

        return responseRequest[endpoint.valueResponse];
    };

    /**
     * Handle input subtitles
     * @param {Object} e Class Event of component
     * @return {void}
     */
    const handleInputSubtitles = async e => {
        if (e.key === 'Enter' && !e.shiftKey && inputSubtitles && inputSubtitles.trim() !== '') {
            e.preventDefault();
            const msg = buildMessageSubtitle(username, sourceLanguage.value, inputSubtitles);

            if (provider === 'pubnub' && pubnub) {
                pubnub.publish({
                    channel: channelIdPubnub,
                    message: JSON.stringify(msg),
                });
            }
            displaySubtitle(sourceLanguage.value, targetLanguage.value, inputSubtitles, username);
            setInputSubtitles(null);
        }
        if (e.key === 'Enter' && !e.shiftKey && (!inputSubtitles || inputSubtitles.trim() == '')) {
            e.preventDefault();
            return false;
        }
    };

    /**
     * Update class of each <p> element to change font size
     * @param {Integer} size Value to set new font size
     * @return {void}
     */
    const handleFontSize = size => {
        setFontSize(size);
    };
    ts;
    /**
     * Set scroll to the bottom when new text is rendered
     */
    const handleScroll = () => {
        var container = document.getElementById('no_text_verification');
        container.scrollTop = container.scrollHeight;
    };

    /**
     * Show/hide bottom options of container
     */
    const toggleOptions = () => {
        setVisibleOptions(!visibleOptions);
    };

    /**
     * Delete subtitles list, set array by default []
     */
    const deleteContent = () => {
        renderSubtitle(null);
    };

    /**
     * Hide container
     */
    const closeComponent = () => {
        dispatch({
            type: 'SUBTITLES_changeVisible',
            value: false,
        });
    };

    /**
     * Handle source language
     * @param {String} language en | es | fr
     */
    const handleSourceLanguage = language => {
        dispatch({
            type: 'SUBTITLES_changeSourceLanguage',
            value: language,
        });
    };

    /**
     * Handle target language
     * @param {String} language en | es | fr
     */
    const handleTargetLanguage = language => {
        dispatch({
            type: 'SUBTITLES_changeTargetLanguage',
            value: language,
        });

        // Update value of TTS language to request with the right sourceLanguage param (/TextToSpeech endpoint)
        dispatch({
            type: 'TTS_changeLanguage',
            value: language,
        });

        // Update value of TTS voice to request with the right voice param (/TextToSpeech endpoint)
        // there are languages that doesn't have voices, must set up voice = null
        const newLanguage = languages.find(lng => lng.value === language.value);
        const supportTTS = newLanguage.services.indexOf('tts') > -1;
        dispatch({
            type: 'TTS_changeVoice',
            value: supportTTS || null,
        });
    };

    const handleSTT = () => {
        dispatch({
            type: 'STT_changeTarget',
            value: 'subtitles',
        });
        dispatch({
            type: 'STT_changeActive',
            value: !stt.active,
        });
    };

    const handleTTS = () => {
        dispatch({
            type: 'TTS_changeActive',
            value: !activeTTS,
        });
        handleAutoPlay();
    };

    const handleAutoPlay = () => {
        if (!firstPlayAudio) {
            const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isiOS || isSafari) {
                const promise = audioTTS.play();
                if (promise !== undefined) {
                    promise
                        .catch(error => {
                            // Auto-play was prevented
                            console.log(error);
                        })
                        .then(() => {
                            // Auto-play started
                            audioTTS.pause();
                        });
                }
            }
            firstPlayAudio = true;
        }
    };

    const printName = (sub, index) => {
        if (subtitles[index - 1] === undefined)
            return <p className={`subtitles-name font-size-${fontSize}`}>{sub.name}</p>;
        else if (subtitles[index - 1].name !== sub.name)
            return <p className={`subtitles-name font-size-${fontSize}`}>{sub.name}</p>;
        else if (subtitles[index - 1].name === sub.name) return null;
    };

    return (
        <div className={`wrapper-container ${!visible ? 'container-disabled' : ''}`} style={style}>
            <div id="subtitles" className={`container-options`}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Subtitles</span>
                    </div>
                    <div className="header-options">
                        {/* render props */}
                        {render && render(subtitles)}
                        {/* render props */}

                        {ttsOption && voiceTTS && (
                            <button
                                className={`a-element ${activeTTS ? 'color-green' : ''}`}
                                onClick={handleTTS}
                            >
                                {activeTTS ? <VolumeDown /> : <VolumeMute />}
                            </button>
                        )}
                        <button className="a-element" onClick={deleteContent}>
                            <TrashAlt />
                        </button>
                        <button className="a-element" onClick={toggleOptions}>
                            <Cog />
                        </button>
                        {showButtonCloseComponent ? (
                            <button className="a-element" onClick={closeComponent}>
                                <Times />
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="box-subtitle">
                    <div
                        id="no_text_verification"
                        className={`body-container ${!visibleOptions ? 'options-disabled' : ''} ${
                            !showInput ? 'no-input' : ''
                        }`}
                    >
                        {subtitles.map((sub, index) => (
                            <div key={`sub-${index}`}>
                                {printName(sub, index)}
                                {sub.bilingualText ? (
                                    <p className={`font-size-${fontSize} bilingual-text`}>
                                        <ArrowRight />
                                        &nbsp;
                                        {sub.bilingualText}
                                    </p>
                                ) : null}
                                <p className={`font-size-${fontSize}`}>
                                    <ArrowRight />
                                    &nbsp;
                                    {sub.text}
                                </p>
                            </div>
                        ))}
                    </div>
                    {showInput ? (
                        <div
                            className={`wrapper-input-subtitles ${
                                speechRecognition ? 'wrapper-input-subtitles-group' : ''
                            }`}
                        >
                            {speechRecognition ? (
                                <div className="wrapper-button-speech-subtitles">
                                    <button
                                        type="button"
                                        className={`btn ${
                                            stt.active && stt.target === 'subtitles'
                                                ? 'btn-green'
                                                : ''
                                        }`}
                                        disabled={stt.active && stt.target !== 'subtitles'}
                                        onClick={handleSTT}
                                    >
                                        {stt.active && stt.target === 'subtitles' ? (
                                            <Microphone />
                                        ) : (
                                            <MicrophoneSlash />
                                        )}
                                    </button>
                                </div>
                            ) : null}
                            <div className="wrapper-input-subtitles">
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Type text here"
                                    rows="4"
                                    value={inputSubtitles || ''}
                                    onKeyPress={handleInputSubtitles}
                                    onClick={handleAutoPlay}
                                    onChange={e => setInputSubtitles(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={`bottom-box ${!visibleOptions ? 'options-disabled' : ''} ${
                            !showInput ? 'no-input' : ''
                        }`}
                    >
                        <div className="form-inline">
                            <div className="settings-container">
                                <div className="settings-container-wrapper">
                                    <span className="settings-container-label">
                                        Subtitles font size
                                    </span>
                                    <div className="size-subtitles">
                                        <label style={{ fontSize: 10 }}>A</label>
                                        <input
                                            type="range"
                                            min="1"
                                            max="5"
                                            step="1"
                                            value={fontSize}
                                            onChange={e => handleFontSize(e.target.value)}
                                        ></input>
                                        <label style={{ fontSize: 14 }}>A</label>
                                    </div>
                                </div>
                                {showInput &&
                                (showTargetLanguageSelect || showSourceLanguageSelect) ? (
                                    <div className="settings-container-wrapper">
                                        <span className="settings-container-label">Bilingual</span>
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={bilingual}
                                                onChange={e => setBilingual(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="settings-container">
                                {showTargetLanguageSelect ? (
                                    <div className="settings-container-wrapper target-language">
                                        <span className="settings-container-label">
                                            Target Language
                                        </span>
                                        <div>
                                            <Select
                                                handleChange={handleTargetLanguage}
                                                data={languages}
                                                defaultValue={languages.find(
                                                    item => item.value === initialTargetLanguage,
                                                )}
                                                value={targetLanguage}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {showInput && showSourceLanguageSelect ? (
                                    <div className="settings-container-wrapper source-language">
                                        <span className="settings-container-label">
                                            Source Language
                                        </span>
                                        <div>
                                            <Select
                                                handleChange={handleSourceLanguage}
                                                data={languages}
                                                defaultValue={languages.find(
                                                    item => item.value === initialTargetLanguage,
                                                )}
                                                value={sourceLanguage}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubtitlesUI;
