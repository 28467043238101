import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StateProvider, useStateValue, useTokenContext } from './store';
import { getTokens } from './request/auth';
import fetchLanguages from './request/languages';

import pubnub from './pubnub';

import Autologin from './components/autologin';
import Select from './components/select';

import Chat from './chat';
import TextToSpeech from './tts';
import Subtitles from './subtitles';
import SpeechToText from './stt';

import './index.scss'; // change to .css to publish

const MainWrapper = props => {
    // debugInterface: if true, test styles without call endpointSession
    // endpointSession: endpoint to get tokens of vendors (providers) (API WWTC)
    // vendors: list of providers to use services (TTT, TTS, STT - Microsoft, google)
    const {
        autologin,
        debugInterface,
        endpointSession,
        gender,
        language,
        roomName,
        userName,
        vendors,
    } = props;
    var audioTTS = new Audio('https://sdkresources.s3.amazonaws.com/assets/Silent.wav');
    const [initialInfo, setInitialInfo] = useState({
        loaded: false,
        tokens: null,
        languages: [],
    });

    useEffect(() => {
        fetchLanguages()
            .then(languages => {
                if (!debugInterface) {
                    fetchInitialInfo(languages);
                } else {
                    setInitialInfo({
                        loaded: true,
                        tokens: null,
                        languages,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    // get tokens from endpointSession to set in global state
    // to distribute in each component (chat, subtitles, stt, tts)
    const fetchInitialInfo = async languages => {
        if (vendors && endpointSession) {
            const tokens = await getTokens(vendors, endpointSession);
            setInitialInfo({
                loaded: true,
                tokens,
                languages,
            });
        } else {
            console.log('Vendors or endpointSession are undefined or null');
            setInitialInfo({
                loaded: true,
                tokens: null,
                languages,
            });
        }
    };

    if (!initialInfo.loaded) {
        return (
            <div style={stylesLoader.wrapper}>
                <div style={stylesLoader.textCenter}>
                    <img
                        src="https://sdkresources.s3.amazonaws.com/assets/oval.svg"
                        style={stylesLoader.img}
                    />
                    <h1 style={stylesLoader.title}>Loading app...</h1>
                </div>
            </div>
        );
    }
    if (autologin) {
        return (
            <StateProvider
                tokens={initialInfo.tokens}
                audioTTS={audioTTS}
                languages={initialInfo.languages}
            >
                <Autologin>{props.children}</Autologin>
            </StateProvider>
        );
    } else {
        const childrenWithExtraProp = React.Children.map(props.children, child => {
            return React.cloneElement(child, {
                conferenceName: roomName,
                initGender: gender || '',
                initLanguage: language,
                initName: userName,
            });
        });
        return (
            // audioTTS is the variable to play the tts audio
            // variable is sent from this component because must be one instance of audio player
            // to solve iOS bug
            // 07/23/2020
            // Romer M
            <StateProvider
                tokens={initialInfo.tokens}
                audioTTS={audioTTS}
                languages={initialInfo.languages}
            >
                {childrenWithExtraProp}
            </StateProvider>
        );
    }
};

MainWrapper.defaultProps = {
    autologin: true,
    debugInterface: false,
};

MainWrapper.propTypes = {
    autologin: PropTypes.bool,
};

const stylesLoader = {
    wrapper: {
        width: '100vw',
        height: '100vh',
        background: '#ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        height: '10vw',
        height: '10vw',
    },
    title: {
        textAlign: 'center',
        color: '#999',
    },
    textCenter: {
        textAlign: 'center',
    },
};

export {
    Autologin,
    Chat,
    MainWrapper,
    Select,
    SpeechToText,
    StateProvider,
    Subtitles,
    TextToSpeech,
    fetchLanguages,
    pubnub,
    useStateValue,
    useTokenContext,
};
